.service-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: start;
    width: 100%;
    min-height: 75vh;
    gap: 20px;
}

.animate-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 590px;
    max-height: 590px;
    width: auto;
    height: auto;
    margin-top: 112px;
    margin-bottom: 50px;
}

@media screen and (max-width: 880px){
    .animate-container{
        margin-top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 0px;
        height:530px;
    }
    
}