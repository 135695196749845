
.graphic-five-container-m{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 520px;
    scale: 0.8;
    color: white;
}

.font-resources-gf{
    font-family: Nunito;
    font-weight: 400;
    font-size: 22px;
    max-width: 150px;
    text-align: center;
}

.graphic-five-m{
    width: 270px;
    height: 520px;
    position: relative;
}

.gf-mobile{
    display: none;
    opacity: 0;
}

.gf-text-1{
    position: absolute;
    top: 50px;
    left: 58px;
    opacity: 0;
}
.gf-text-2{
    position: absolute;
    top: 245px;
    left: 93px;
    opacity: 0;
}
.gf-text-3{
    position: absolute;
    top: 411px;
    left: 59px;
    opacity: 0;
}

.gfi-triangle{
    position: absolute;
    top: 22%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    opacity: 0;
}
.gfi-circle{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    opacity: 0;
}

.gfi-diamond{
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    opacity: 0;
}



.gfi-circle.gfi-res-animation{
    animation: circle-anim-gfi 1.5s ease forwards;
}
.gfi-triangle.gfi-res-animation{
    animation: rotate-res 1s ease forwards 1.5s;
}
.gfi-diamond.gfi-res-animation{
    animation: rotate-res 1s ease forwards 2.5s;
}

.gf-text-1.gfi-text-animation, .gf-text-2.gfi-text-animation, .gf-text-3.gfi-text-animation{
    animation: circle-anim-gfi 1s ease forwards 3.5s;
}


@keyframes rotate-res{
    0%{
        transform: translate(-50%, -50%) rotate(0deg);
        opacity: 0;
    }
    100%{
        transform: translate(-50%, -50%) rotate(360deg);
        opacity: 1;
    }
    
}

@keyframes circle-anim-gfi {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
    
}


@media screen and (max-width: 880px){
    .gf-mobile{
        display: flex;
        opacity: 1;
    }
    
    
}