@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital@0;1&display=swap');




.slide_container{
    z-index: 0;
    position: relative;
    padding-top: 30px;

    
    width: 100%;
    height: 100%;
    min-height: 90vh;
}

body{
    background-image: url("../../img/background.jpg");
    background-size: cover;
}

.splide__pagination{
    position: fixed !important;
}

.splide__pagination li{
    background-color: #404040;

    padding: 2px 2px 4px 2px;
}

.splide__pagination li:first-child {
    /* Estilo para el primer li */
    border-radius: 10px 0 0 10px;
    padding-left: 10px;
  }
  
  .splide__pagination li:last-child {
    /* Estilo para el último li */
    border-radius: 0 10px 10px 0;
    padding-right: 10px;
  }

  .splide__pagination li button.is-active{
    background-color: #ffffff;
    opacity: 1;
    transform: scale(1.6);
  }
  .splide__pagination li button:not(.is-active) {
    opacity: 0.5;
    transform: scale(.7);
  }



.splide__arrows{
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 1;
    top: 50%;
}

.copyright{
    font-family: Plus Jakarta Sans;
    font-size: 11px;
    font-weight: 400;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ACACAC;
    padding-bottom: 80px;
}

.page_container{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    color: white;
    padding-bottom: 50px;
    padding: 0px 50px 50px 50px;
    text-align: center;
}

.splide-slide{
    height: 85vh !important;
  }

.splide-slide.is-active{
    height: auto !important;
}


.navbar{
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: start;
    background-color: black;
    width: 100%;
}

.navbar_container{
    /* max-width: 1440px; */
    width:100%;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    z-index: 2;
    justify-content: space-between;
    align-items: center;
    color: white;
    height: 100px;
    background-color: black;
    position: fixed;
}

.nav_logo{
    margin: 15px;
    width: 15%;
    min-width: 155px;
    height: auto;
    cursor: pointer;
}

.nav_items{
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    width: 77vw;
    max-width: 800px;
    gap: 20px;
    min-width: 500px;
    padding-right: 19px;
    text-wrap: nowrap;
}
.nav_item{
    cursor: pointer;
    color: white;
    transition: .3s ease;
}
.nav_item:hover{
    transition: .3s ease;
    color: #7B45D3;

}


.splide__arrow svg {
    fill: white !important;
    width: 2.2em;
    height: 2.2em;
}

.splide__arrow {
    background: none !important;
}

.nav_toggle{
    display: none;
}

button{
    background: none;
    border: 0;
}
button:active{
    background-color: rgb(41, 41, 41);
}

.nav_item_active{
    color: #7B45D3;
    cursor: pointer;
    border-bottom-style: solid;
    border-color: #7B45D3;
}


@media (max-width: 880px){
    .nav_items{
        position: fixed;
        top: -105vh;
        left: 0;
        padding-top: 110px;
        padding-right: 0px;
        font-size: 18px;
        z-index: -1;
        height: 100%;
        min-width: 300px;
        max-width: none;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        gap: 30px;
        background-image: url("../../img/menu_background.jpg");
        background-size: cover;
        transition: .7s;
        transform: translateY(110vh);
    }


    .nav_toggle{
        display: inline-block;
        margin-right: 30px;
    }

    .responsive_nav{
        transform: none;
    }

    .nav_logo{
        margin: 15px;
        width: 10%;
        min-width: 70px;
        height: auto;
    }
}