@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.paragraph-page{
    font-family: Nunito;
    font-weight: 400;
    font-size: 20px;
    max-width: 700px;
}

.tittle-page{
    font-family: Poppins;
    font-weight: 600;
    font-size: 45px;
    max-width: 800px;
    width: 100%;
}
.subtittle-page{
    font-family: Poppins;
    font-size: 33px;
    font-weight: 600;
    text-align: center;
    max-width: 600px;
    margin: 50px 0px 50px 0px;
}
.subtittle-page-smaller{
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    max-width: 600px;
    margin: 50px 0px 50px 0px;
}

.font-resources{
    font-family: Nunito;
    font-weight: 400;
    font-size: 18px;
    max-width: 150px;
    text-align: center;
}

.mobile{
    display: none;
}
.desktop{
    visibility: inline-block;
}

@media (max-width: 880px){
    .mobile{
        display: inline-block;
    }
    .desktop{
        display: none;
    }

    .tittle-page{
        font-size: 30px;
    }
    .paragraph-page{
        font-size: 14px;
    }
}