.graphic-four-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-bottom: 40px;
}

.graphic-four{
    width: 400px;
    height: 500px;
    position: relative;

}

.gf-circle-1{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    transform: scale(0);

}
.gf-circle-2{
    display: inline;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    transform-origin: center;
    transform: scale(0);
}
.gf-circle-3{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    transform-origin: center;
    transform: scale(0);
}


/* no quiero infinite */
.gf-circle-1.circles-animation {
    animation: circles-animation .5s ease forwards .5s;
}
.gf-circle-2.circles-animation {
    animation: circles-animation .5s ease forwards 1s;
}
.gf-circle-3.circles-animation {
    animation: circles-animation .5s ease forwards 1.5s;
}

.minicircle-1, .minicircle-2 {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    transform: scale(0);
}

.minicircle-1 {
    top: 60%;
    left: 7%;
    transform: translate(-50%, -50%);
    opacity: 0;    
}

.minicircle-2 {
    top: 71%;
    left: 68%;
    transform: translate(-50%, -50%);
    opacity: 0;
}

.vertical-line-1, .vertical-line-2 {
    position: absolute;
    width: 1px;
    opacity: 0;
    background-color: #ffffff;
}

.vertical-line-1 {
    top: 83%;
    left: 6.8%;
    transform: translate(-50%, -50%);
    height: 200px;
}

.vertical-line-2 {
    top: 88.3%;
    left: 68%;
    transform: translate(-50%, -50%);
    height: 145px;
}

.minicircle-animation{
    animation: minicircle-animation .5s ease forwards 2.3s;
}

.vertical-line-1.line-animation, .vertical-line-2.line-animation {
    animation: minicircle-animation .5s ease forwards 2.7s;
}

.gf-div{
    color: white;
    display: flex;
    flex-direction: column;
    width: 100px;
    opacity: 0;
}
.gf-div-1{
    justify-content: center;
    align-items: start;
    position: absolute;
    top: 396px;
    left: 50px;
}
.gf-div-2{
    justify-content: center;
    align-items: start;
    position: absolute;
    top: 407px;
    left: 299px;
}
.gf-div-3{
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 192px;   
    left: 150px;
}

.gf-align{
    text-align: left;
}

.gf-div.div-animation{
    animation: minicircle-animation .5s ease forwards 3.2s;
}

@keyframes minicircle-animation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes circles-animation {
    0% {
        transform: translate(-50%, -50%) scale(0);
    }

    80%{
        transform: translate(-50%, -50%) scale(1.3);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
    

}

@media screen  and (max-width: 880px){
    .graphic-four-container{
        scale: 0.7;
        margin-bottom: 0px;
    }
}