.img-page-six{
    max-width: 1440px;
    max-height: 608px;
    width: 100%;
    height: 100%;
    padding: 0px 50px 0px 50px;
}

.choose-container{
    width: 100%;
    height: min-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    padding-bottom: 50px;
    text-align: center;
}

@media (max-width: 880px){
    .img-page-six{
        padding: 30px 0px 0px 0px;
    }
}