.gf-desktop{
    display: flex;
    opacity: 1;
}


.graphic-five-container-d{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-width: 520px;
    scale: 0.8;
    color: white;
}

.font-resources-gf{
    font-family: Nunito;
    font-weight: 400;
    font-size: 22px;
    max-width: 150px;
    text-align: center;
}

.graphic-five-d{
    width: 540px;
    height: 300px;
    position: relative;
}

.gf-text-1-d{
    position: absolute;
    top: 50%;
    left: 3px;
    transform: translateY(-50%);
    opacity: 0;
}
.gf-text-2-d{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
}
.gf-text-3-d{
    position: absolute;
    top: 50%;
    left: 72%;
    transform: translateY(-50%);
    opacity: 0;
}

.gfi-triangle-d{
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%) rotate(270deg);
    transform-origin: center;
    opacity: 0;
}
.gfi-circle-d{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    opacity: 0;
}

.gfi-diamond-d{
    position: absolute;
    top: 50%;
    left: 86%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    opacity: 0;
}



.gfi-circle-d.gfi-res-animation{
    animation: circle-anim-gfi-d 1.5s ease forwards;
}
.gfi-triangle-d.gfi-res-animation{
    animation: rotate-res-d 1s ease forwards 1.5s;
}
.gfi-diamond-d.gfi-res-animation{
    animation: rotate-res-d 1s ease forwards 2.5s;
}

.gf-text-1-d.gfi-text-animation, .gf-text-2-d.gfi-text-animation, .gf-text-3-d.gfi-text-animation{
    animation: circle-anim-gfi-d 1s ease forwards 3.5s;
}


@keyframes rotate-res-d{
    0%{
        transform: translate(-50%, -50%) rotate(0deg);
        opacity: 0;
    }
    100%{
        transform: translate(-50%, -50%) rotate(270deg);
        opacity: 1;
    }
    
}

@keyframes circle-anim-gfi-d {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
    
}


@media screen and (max-width: 880px){

    .gf-desktop{
        display: none;
        opacity: 0;
    }
    
    
}