.tittle-core{
    font-family: Poppins;
    font-weight: 600;
    font-size: 35px;
    text-align: center;
}

.description-core{
    font-family: Nunito;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    color: white;
}

.img-core{
    max-width: 180px;
    max-height: 180px;
    width: 100%;
    height: 100%;
}

.core{
    max-width: 292px;
    max-height: 387px;
    width: 100%;
    height: 100%;
}
.core-dif{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 590px;
    max-height: 590px;
    width: auto;
    height: auto;
    margin-top: 100px;
    margin-bottom: 50px;
}


.img-core-dif{
    width: 100%;
    height: 100%;
    max-width: 590px;
    max-height: 590px;
}
.core-dif2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 460px;
    max-height: 590px;
    width: auto;
    height: auto;
    margin-top: 100px;
    margin-bottom: 50px;
}


.img-core-dif2{
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 377px;
    object-fit: contain;
}

@media screen and (max-width: 880px){
    .tittle-core{
        font-size: 25px;
    }
    
    .description-core{
        font-size: 14px;
    }
}